import React, {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';

export const Search: FC<{value: string, onChange(e: React.ChangeEvent<HTMLInputElement>): void, placeholder?:string}> = (props) => {

    const [id] = useState<string>(uuidv4());

    return(
        <div className={"mb-10"}>
            <label htmlFor={id} className="sr-only">Search</label>
            <div className="relative mt-1">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <FontAwesomeIcon className={"text-gray-400"} icon={faMagnifyingGlass}/>
                </div>
                <input value={props.value} onChange={props.onChange} type="text" id={id}
                       className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-2 focus:outline-blue-500 outline-offset-0 focus:outline block w-80 pl-10 p-2.5 dark:bg-zinc-700 dark:border-zinc-600 dark:placeholder-zinc-400 dark:text-white focus:ring-0 dark:focus:outline-purple-500"
                       placeholder={props.placeholder}/>
            </div>
        </div>
    )
}