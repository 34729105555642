import {BadgeColor, PBadge} from "../../Components/Badge";

export interface Language extends PBadge{
}

export const Languages: {[name: string]: Language} = {
    TYPESCRIPT: {
        text: "TypeScript",
        color: BadgeColor.PURPLE
    },
    PHP: {
        color: BadgeColor.RED,
        text: "PHP"
    },
    CSHARP: {
        color: BadgeColor.DARK,
        text: "C#"
    },
    HTML: {
        color: BadgeColor.RED,
        text: "HTML"
    },
    CSS: {
        color: BadgeColor.YELLOW,
        text: "CSS"
    },
    JAVA: {
        color: BadgeColor.BLUE,
        text: "Java"
    }
}