import {FC, useEffect} from "react";
import {useMeta} from "../uses/useMeta";
import {Presentation} from "../Features/Slider/Presentation";

export const TechnologyPage: FC = () => {

    return(
        <>
            {useMeta({title: "Veille technologique"})}
            <div className={"w-full h-full -mt-10 absolute"}>
                <Presentation/>
            </div>
        </>
    )
}