import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import {Technologies} from "./Properties/Technologies";
import React, {FC, ReactElement} from "react";
import {Viewer, Worker} from "@react-pdf-viewer/core";

import {defaultLayoutPlugin, ToolbarProps} from '@react-pdf-viewer/default-layout';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {toolbarPlugin} from "@react-pdf-viewer/toolbar";
import {PDF} from "../Components/PDF";
import {Types} from "./Properties/Type";

const Content: FC = () => {

    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Durant ce projet, nous avions pour objectif de travailler sur <b>l'identité numérique d'une entreprise</b>, ici Lorrain’échecs.<br/>
                Je me suis personnellement occupé de la partie <b>référencement</b>, celle-ci consistait à me renseigner sur les différentes façons d'<b>améliorer le référencement d'un site</b>.
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Résultat
            </h1>
            <p>
                <PDF path={"/files/works/ap22/referencement.pdf"}/>
            </p>
        </div>
    );
}

export const Ap22Work: Work = {
    id: "ap22",
    type: Types.BTS,
    title: "AP - Identité numérique",
    description: "Comment améliorer le référencement d'un site internet ?",
    date: "Février 2021",
    skills: {
        "skill3": ["subskill1"] //Recherches liés au SEO, etc..
    },
    content: <Content/>
}