import {createContext, FC, useEffect, useState} from "react";

export const DarkModeContext = createContext(null);

export const DarkModeContextProvider: FC = (props) =>{

    const [darkMode, setDarkMode] = useState<boolean>(localStorage.getItem("darkmode") === "on");

    useEffect(() => {
        if(darkMode){
            document.documentElement.classList.add("dark")
            localStorage.setItem("darkmode", "on");
        }else{
            document.documentElement.classList.remove("dark")
            localStorage.removeItem("darkmode")
        }
    }, [darkMode])

    return(
        // @ts-ignore
        <DarkModeContext.Provider value={[darkMode, setDarkMode]}>
            {props.children}
        </DarkModeContext.Provider>
    );
}