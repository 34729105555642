import React, {FC, useContext, useEffect, useState} from 'react';
import {RevealJS, Slide, H1, Code, P, H2, Ul, Li, H6, H4, H3, H5, Image} from '@gregcello/revealjs-react';
import RevealHighlight from "reveal.js/plugin/highlight/highlight";
import RevealNotes from "reveal.js/plugin/notes/notes";
import 'reveal.js/plugin/highlight/monokai.css'
import {DarkModeContext} from "../../Contexts/DarkModeContext";
import {createPortal} from "react-dom";
import {CSS} from "../../Components/CSS";
import {usePrevious} from "../../uses/usePrevious";
import { useNavigate } from "react-router-dom";

export const Presentation: FC =() => {

    //@ts-ignore
    const [darkMode, setDarkMode] = useContext(DarkModeContext);
    const oldDarkMode = usePrevious(darkMode);
    const navigate = useNavigate();

    useEffect(() => {
        if(oldDarkMode !== undefined) navigate(0);
    }, [darkMode])



    return (
        <>
            <CSS href={"styles/slider/slider.css"}/>
            <CSS href={"styles/slider/" + (darkMode ? "black" : "white") +".css"}/>
            {/*@ts-ignore*/}
            <RevealJS plugins={[RevealHighlight, RevealNotes]}>
                <Slide>
                    <H1>Fast API</H1>
                    <P>Framework d'API Web</P>
                </Slide>
                <Slide>
                    <H2>Qu'est-ce que c'est ?</H2>
                    <Ul>
                        <Li>Framework pour Python</Li>
                        <Li>API Web</Li>
                        <Li>Open source</Li>
                        <Li>Créé par Sebastián Ramírez</Li>
                    </Ul>
                </Slide>
                <Slide>
                    <H2>Fonctionnalités</H2>
                    <Ul>
                        <Li>Création d'endpoints <i>(GET, POST, etc)</i></Li>
                        <Li>Documentation automatique <i>(Swagger)</i></Li>
                        <Li>Utilisation du typage avec Python</Li>
                        <Li>Compatible avec de nombreux projets open source</Li>
                    </Ul>
                </Slide>
                <Slide>
                    <H2>Quelques exemples avec du code</H2>
                </Slide>
                <Slide>
                    <Slide>
                        <H2>Créer une API avec FastAPI</H2>
                        <H4>Arborescence du projet</H4>
                        <Code language={"Python"}>
                            {{
                                code: `
                            api_example/
                            ├─ data/
                            │  ├─ user_data.py
                            ├─ routers/
                            │  ├─ user_router.py
                            ├─ api.py
                            ├─ main.py
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Liste d'utilisateurs</P>
                        <Code lineNumbers language={"Python"}>
                            {{
                                code: `
                                #data/user_data.py
                                
                                users = [
                                    {
                                        "id": 1,
                                        "firstname": "Aymon",
                                        "lastname": "Ruest",
                                    },
                                    {
                                        "id": 2,
                                        "firstname": "Harcourt",
                                        "lastname": "Brunault",
                                    },
                                    {
                                        "id": 3,
                                        "firstname": "Roxanne",
                                        "lastname": "Jodion",
                                    }
                                ]
                              `,
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Routeur pour accéder aux utilisateurs</P>
                        <Code lineNumbers language={"Python"}>
                            {{
                                code: `
                                #routers/user_router.py
                                
                                from fastapi import APIRouter
                                from data.user_data import users
                                from typing import Dict
                                
                                class UserRouter:
                                    router: APIRouter = APIRouter()
                                
                                    @staticmethod
                                    @router.get("/")
                                    async def read_all():
                                        return users
                                
                                    @staticmethod
                                    @router.get("/{id}")
                                    async def read_one(id: int):
                                        #Renvoyer l'utilisateur avec l'id demandé (None sinon)
                                        return next((user for user in users if user['id'] == id), None)
                                
                                    @staticmethod
                                    @router.post("/")
                                    async def create(user_data: Dict):
                                        #Récupérer l'id maximum parmis les utilisateurs
                                        user_data["id"] = max((user['id'] for user in users), default=0) + 1
                                        #Ajouter le nouvel utilisateur à la liste
                                        users.append(user_data)
                                        #Renvoyer le nouvel utilisateur avec son id
                                        return user_data
                                
                                    @staticmethod
                                    @router.delete("/{id}")
                                    async def delete(id: int):
                                        #Chercher l'utilisateur avec l'id demandé
                                        user = next((user for user in users if user['id'] == id), None)
                                        #Si il existe
                                        if user:
                                            #Le supprimer de la liste
                                            users.remove(user)
                                        return None
                                
                                    @staticmethod
                                    @router.patch("/{id}")
                                    async def patch(id: int, user_data: Dict):
                                        user_data["id"] = id
                                        #Chercher l'utilisateur avec l'id demandé
                                        user = next((user for user in users if user['id'] == id), None)
                                        #Si il existe
                                        if user:
                                            #Le mettre à jour
                                            user.update(user_data)
                                        return user
                              `,
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Créer l'API</P>
                        <Code lineNumbers language={"Python"}>
                            {{
                                code: `
                                #api.py
                                
                                from fastapi import APIRouter, FastAPI
                                from routers.user_router import UserRouter
                                
                                router = APIRouter()
                                router.include_router(UserRouter.router, prefix="/users")
                                
                                api = FastAPI(title="Mon API", docs_url="/")
                                api.include_router(router)
                              `,
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Créer le serveur</P>
                        <Code lineNumbers language={"Python"}>
                            {{
                                code: `
                                #main.py
                                
                                import uvicorn

                                if __name__ == '__main__':
                                    uvicorn.run(
                                        "api:api", host="127.0.0.1", port=8000, reload=True
                                    )
                              `,
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Résultat</P>
                        <Image src={"/images/presentation/fastapi_base.webp"}/>
                    </Slide>
                </Slide>
                <Slide>
                    <Slide>
                        <H2>Validation des données</H2>
                        <H4>Nouvelle arborescense</H4>
                        <Code language={"Python"}>
                            {{
                                code: `
                            api_example/
                            ├─ data/
                            │  ├─ user_data.py
                            ├─ routers/
                            │  ├─ user_router.py
                            ├─ schemas/ (AJOUTÉ)
                            │  ├─ user_schemas.py (AJOUTÉ)
                            ├─ api.py
                            ├─ main.py
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Créer les schémas</P>
                        <Code language={"Python"}>
                            {{
                                code: `
                            #schemas/user_schemas.py
                            
                            from pydantic import BaseModel
                            from typing import Optional
                            
                            class UserSchema(BaseModel):
                                firstname: str
                                lastname: str
                            
                            class UserRead(UserSchema):
                                id: int
                            
                            class UserCreate(UserSchema):
                                pass
                            
                            class UserPatch(UserSchema):
                                id: Optional[int]
                                firstname: Optional[str]
                                lastname: Optional[str]
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Mettre à jour le routeur</P>
                        <Code language={"Python"} lineNumbers={"7,13,18,27,28,46,47"}>
                            {{
                                code: `
                            #routers/user_router.py
                            
                            from fastapi import APIRouter, HTTPException
                            from http import HTTPStatus
                            from data.user_data import users
                            from typing import Dict, List
                            from schemas.user_schemas import UserCreate, UserPatch, UserRead
                            
                            class UserRouter:
                                router: APIRouter = APIRouter()
                            
                                @staticmethod
                                @router.get("/", response_model=List[UserRead])
                                async def read_all():
                                    return users
                            
                                @staticmethod
                                @router.get("/{id}", response_model=UserRead)
                                async def read_one(id: int):
                                    #Renvoyer l'utilisateur avec l'id demandé (None sinon)
                                    user = next((user for user in users if user['id'] == id), None)
                                    if user:
                                        return user
                                    raise HTTPException(HTTPStatus.NOT_FOUND)
                            
                                @staticmethod
                                @router.post("/", response_model=UserRead)
                                async def create(user: UserCreate):
                                    #Ajouter le nouvel utilisateur à la liste
                                    users.append({"id": max((old_user['id'] for old_user in users), default=0) + 1, **user.dict()})
                                    #Renvoyer le nouvel utilisateur avec son id
                                    return users[-1]
                            
                                @staticmethod
                                @router.delete("/{id}")
                                async def delete(id: int):
                                    #Chercher l'utilisateur avec l'id demandé
                                    user = next((user for user in users if user['id'] == id), None)
                                    #Si il existe
                                    if user:
                                        #Le supprimer de la liste
                                        users.remove(user)
                                    return None
                            
                                @staticmethod
                                @router.patch("/{id}", response_model=UserRead)
                                async def patch(id: int, user: UserPatch):
                                    user.id = id
                                    #Chercher l'utilisateur avec l'id demandé
                                    old_user = next((user for user in users if user['id'] == id), None)
                                    #Si il existe
                                    if user:
                                        #Le mettre à jour
                                        old_user.update(user.dict(exclude_unset=True))
                                        return old_user
                                    raise HTTPException(HTTPStatus.NOT_FOUND)
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Résultat</P>
                        <Image src={"/images/presentation/fastapi_validation.webp"}/>
                    </Slide>
                </Slide>

                <Slide>
                    <Slide>
                        <H2>Sécurisation des routes</H2>
                        <H4>Nouvelle arborescense</H4>
                        <Code language={"Python"}>
                            {{
                                code: `
                            api_example/
                            ├─ data/
                            │  ├─ user_data.py
                            ├─ routers/
                            │  ├─ dependencies/ (AJOUTÉ)
                            │  │  ├─ is_connected.py (AJOUTÉ)
                            │  ├─ user_router.py
                            ├─ schemas/
                            │  ├─ user_schemas.py
                            ├─ api.py
                            ├─ main.py
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Créer la dépendance</P>
                        <Code language={"Python"}>
                            {{
                                code: `
                            #routers/dependencies/is_connected.py
                            
                            from fastapi import Depends, HTTPException
                            from fastapi.security import HTTPBearer
                            
                            oauth2_scheme = HTTPBearer()
                            
                            async def is_connected(
                                token = Depends(oauth2_scheme)
                            ):
                                if token.credentials != "123":
                                    raise HTTPException(status_code=401, detail="Invalid token")
                                return True
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Mettre à jour le routeur</P>
                        <Code language={"Python"} lineNumbers={"6"}>
                            {{
                                code: `
                            #routers/user_router.py
                            
                            ...
                            
                            @staticmethod
                            @router.delete("/{id}", dependencies=[Depends(is_connected)])
                            async def delete(id: int):
                                #Chercher l'utilisateur avec l'id demandé
                                user = next((user for user in users if user['id'] == id), None)
                                #Si il existe
                                if user:
                                    #Le supprimer de la liste
                                    users.remove(user)
                                return None
                                
                            ...
                            `
                            }}
                        </Code>
                    </Slide>
                    <Slide>
                        <P>Résultat</P>
                        <Image src={"/images/presentation/fastapi_security.webp"}/>
                    </Slide>
                </Slide>
            </RevealJS>
        </>
    );
}
