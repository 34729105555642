import {FC} from "react";

export const Section: FC<{title: string}> = (props) =>{

    return(
        <>
            <div className={"bg-gray-100 dark:bg-zinc-800 text-[18px] px-4 py-2 font-medium rounded-t-lg"}>{props.title}</div>
            <div className={"flex flex-wrap px-4 py-3 bg-transparent border border-gray-100 dark:border-zinc-800 rounded-b-lg"}>
                {/*{props.badges.map((badge, index) =>*/}
                {/*    <Badge text={badge.text} color={badge.color}/>*/}
                {/*)}*/}
                {props.children}
            </div>
        </>
    )
}