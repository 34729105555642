import React, {FC} from "react";
import {toolbarPlugin, ToolbarSlot, TransformToolbarSlot} from "@react-pdf-viewer/toolbar";
import {defaultLayoutPlugin, ToolbarProps} from "@react-pdf-viewer/default-layout";
import {Viewer, Worker} from "@react-pdf-viewer/core";

export const PDF: FC<{path: string}> = (props) => {

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar} = toolbarPluginInstance;

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => [
        ],
        renderToolbar
    });

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        GoToLastPage: () => <></>,
        ShowProperties: () => <></>,
        Download: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    return(
        <>
            <Worker workerUrl={"https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js"}>
            </Worker>


            <div
                style={{
                    height: '1000px'
                }}
            >
                <Viewer fileUrl={props.path} plugins={[defaultLayoutPluginInstance]}/>
            </div>
        </>
    )
}