import React from "react";
import {TikiLearningWork} from "./TikiLearningWork";
import {Language} from "./Properties/Languages";
import {Technology} from "./Properties/Technologies";
import {Type} from "./Properties/Type";
import {Ap21Work} from "./Ap21Work";
import {Ap22Work} from "./Ap22Work";
import {Ap23Work} from "./Ap23Work";
import {Ap24Work} from "./Ap24Work";
import {Ap25Work} from "./Ap25Work";
import {Ap31Work} from "./Ap31Work";
import {Ap32Work} from "./Ap32Work";
import {Ap33Work} from "./Ap33Work";
import {Stage1Work} from "./Stage1Work";
import {SubSkill} from "./Properties/Skills";

export interface Work{
    id: string,
    title: string,
    description: string,
    date: string,
    dateEnd?: string,
    content: React.ReactNode,
    languages?: Language[],
    technologies?: Technology[],
    skills?: {[name: string]: string[]}
    type: Type
}

export const Works = [
    Ap33Work, TikiLearningWork, /*Ap32Work,*/ Ap31Work, /*Ap25Work,*/ Ap24Work, Ap23Work, Stage1Work, Ap22Work, Ap21Work
]