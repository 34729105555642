import {FC} from "react";

export enum BadgeColor{
    BLUE = "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800",
    DARK = "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300",
    RED = "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900",
    GREEN = "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900",
    YELLOW = "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900",
    INDIGO = "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900",
    PURPLE = "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900",
    PINK = "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900"
}

const generate =<div className={
    "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800 " +
    "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 " +
    "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 " +
    "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 " +
    "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 " +
    "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 " +
    "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900 " +
    "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900 "
}/>

export interface PBadge{
    text: string,
    color: BadgeColor,
    className?: string
}

export const Badge: FC<PBadge> = (props) =>{

    return(
        <div className={props.color + " w-fit text-xs font-semibold mr-2 last:mr-0 px-2.5 py-0.5 rounded " + props.className}>
            {props.text}
        </div>
    )
}