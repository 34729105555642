import {BadgeColor, PBadge} from "../../Components/Badge";

export interface Technology extends PBadge{
}

export const Technologies: {[name: string]: Technology} = {
    SYMFONY: {
        text: "Symfony",
        color: BadgeColor.GREEN
    },
    REACT: {
        color: BadgeColor.YELLOW,
        text: "React"
    },
    GIT: {
        color: BadgeColor.DARK,
        text: "Git"
    },
    DOCKER: {
        color: BadgeColor.BLUE,
        text: "Docker"
    },
    JAVAFX: {
        color: BadgeColor.RED,
        text: "Java FX"
    },
    ANDROIDSTUDIO: {
        color: BadgeColor.GREEN,
        text: "Android Studio"
    }
}