import React, {FC} from "react";
import {Footer} from "./Footer";

export const Main: FC = (props) => {

    return(
        <main className={"bg-blue-400 flex-1 h-full w-full flex flex-col overflow-y-auto"}>
            <div className={"flex-1 bg-white dark:bg-zinc-900"}>
                <div className={"relative w-[85%] md:w-[80%] lg:w-[70%] mx-auto min-h-full h-fit py-10"}>
                    {props.children}
                </div>
            </div>

            <Footer/>
        </main>
    );
}