import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Work} from "../Works/Works";
import {Badge, BadgeColor, PBadge} from "../Components/Badge";

export interface PWorkCard extends Work{
    badge?: PBadge
}

export const WorkCard: FC<PWorkCard> = (props) => {

    return(
        <div className="p-6 w-full bg-white rounded-lg border border-gray-200 shadow-md dark:bg-zinc-800 dark:border-zinc-700">
            <Link to={"/works/"+props.id}>
                <h5 className="text-2xl mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
                    {props.title}
                </h5>
            </Link>
            {props.languages&&
                <div className={"mb-2 flex flex-wrap"}>
                    {props.languages.map((language, index) =>
                        <Badge key={index} text={language.text} color={language.color}/>
                    )}
                </div>
            }
            <p className="mb-3 font-normal text-gray-700 dark:text-zinc-400">
                {props.description}
            </p>
            <div className={"flex justify-between items-center"}>
                <Link to={"/works/"+props.id}
                   className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                    Lire plus
                    <FontAwesomeIcon className={"ml-2 -mr-1 w-4 h-4"} icon={faArrowRight}/>
                </Link>
                <div className={"text-sm text-neutral-500 flex flex-col sm:flex-row flex-none"}>
                    <div>{props.date}</div>
                    {props.dateEnd&&
                        <div className={"sm:ml-1"}>
                            → {props.dateEnd}
                        </div>
                    }
                </div>
            </div>
            {props.badge&&
                <div className={"h-fit w-full mt-4 flex items-center justify-end"}>
                    <FontAwesomeIcon className={"text-gray-400 mr-2"} icon={faMagnifyingGlass}/>
                    <Badge {...props.badge} />
                </div>
            }
        </div>
    )
}