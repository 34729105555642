import {FC} from "react";

export const Footer: FC = () => {
    return(
        <footer className={"z-10 flex-none h-14 bg-white border-t border-gray-100 flex justify-center items-center dark:bg-zinc-900 dark:border-zinc-800"}>
            <div>
                Fait avec <span className={"text-blue-600 dark:text-purple-500 animate-pulse"}>&#10084;</span> par moi-même.
            </div>
        </footer>
    );
}