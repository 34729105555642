import {Badge, PBadge} from "./Badge";
import {FC} from "react";
import {Section} from "./Section";



export const BadgeSection: FC<{title: string, badges: PBadge[]}> = (props) =>{

    return(
        <Section title={props.title}>
            {props.badges.map((badge, index) =>
                <Badge key={index} className={"my-0.5"} text={badge.text} color={badge.color}/>
            )}
        </Section>
    )
}