import {FC, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {faMaximize, faExpand} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Image: FC<{src: string, className?: string, title?: string}> = (props) =>{

    const [isFS, setIsFS] = useState<boolean>(false);

    return(
        <>
            {isFS?
                createPortal(
                    <div className={"cursor-pointer absolute z-50 bg-zinc-800/80 w-full h-full top-0 left-0 flex flex-col items-center justify-center"} onClick={() => setIsFS(false)}>
                        <img src={props.src} className={"max-w-full lg:max-w-[80%] max-h-[80%]"} />
                        {props.title?<div className={"text-zinc-900 mt-3 text-xl bg-gray-200 border-gray-200 border-2 py-2 px-5 rounded-xl"}>{props.title}</div>:<></>}
                    </div>,
                    //@ts-ignore
                    document.getElementById("root")
                )
                :<></>
            }
            <div onClick={() => setIsFS(true)} className={"relative cursor-pointer " + props.className}>
                <div className={"dark:bg-zinc-700 bg-gray-200 w-full h-full absolute opacity-0 hover:opacity-90 transition-opacity flex items-center justify-center"}>
                    <FontAwesomeIcon className={"dark:text-white text-zinc-700 h-[40px] inline-block align-middle mr-4"} icon={faMaximize} />
                </div>
                <img src={props.src} />
            </div>
        </>
    )
}