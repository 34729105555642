import {FC} from "react";
import {Collapsable, CollapsableColor} from "../Components/Collapsable";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {BadgeColor} from "../Components/Badge";
import {Breadcrumb} from "../Components/Breadcrumb";
import {Search} from "../Components/Form/Inputs/Search";
import {Works} from "../Works/Works";
import {WorkCard} from "../Cards/WorkCard";
import {Skills} from "../Works/Properties/Skills";
import {SkillCard} from "../Cards/SkillCard";
import {useMeta} from "../uses/useMeta";

export const SkillsPage: FC = () => {

    return(
        <div>
            {useMeta({title: "Compétences"})}
            <Breadcrumb includeHome={true} items={[
                {
                    to: "/skills",
                    name: "Compétences"
                }
            ]}/>

            <div className={"mt-10"}>
                {Skills.map((skill, index) =>
                    <SkillCard {...skill}/>
                )}
            </div>
        </div>
    )
}