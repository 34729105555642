
export interface Skill{
    id: string,
    title: string,
    subSkills: SubSkill[]
}

export interface SubSkill {
    id: string,
    title: string
}

export const Skills: Skill[] = [
    {
        id: "skill1",
        title: "Gérer le patrimoine informatique",
        subSkills: [
            {
                id: "subskill1",
                title: "Recenser et identifier les ressources numériques"
            },
            {
                id: "subskill2",
                title: "Exploiter des référentiels, normes et standards adoptés par le prestataire informatique"
            },
            {
                id: "subskill3",
                title: "Mettre en place et vérifier les niveaux d’habilitation associés à un service"
            },
            {
                id: "subskill4",
                title: "Vérifier les conditions de la continuité d’un service informatique"
            },
            {
                id: "subskill5",
                title: "Gérer des sauvegardes"
            },
            {
                id: "subskill6",
                title: "Vérifier le respect des règles d’utilisation des ressources numériques"
            }
        ]
    },
    {
        id: "skill2",
        title: "Répondre aux incidents et aux demandes d’assistance et d’évolution",
        subSkills: [
            {
                id: "subskill1",
                title: "Collecter, suivre et orienter des demandes"
            },
            {
                id: "subskill2",
                title: "Traiter des demandes concernant les services réseau et système, applicatifs"
            },
            {
                id: "subskill3",
                title: "Traiter des demandes concernant les applications"
            }
        ]
    },
    {
        id: "skill3",
        title: "Développer la présence en ligne de l’organisation",
        subSkills: [
            {
                id: "subskill1",
                title: "Participer à la valorisation de l’image de l’organisation sur les médias numériques en tenant compte du cadre juridique et des enjeux économiques"
            },
            {
                id: "subskill2",
                title: "Référencer les services en ligne de l’organisation et mesurer leur visibilité"
            },
            {
                id: "subskill3",
                title: "Participer à l’évolution d’un site Web exploitant les données de l’organisation"
            }
        ]
    },
    {
        id: "skill4",
        title: "Travailler en mode projet",
        subSkills: [
            {
                id: "subskill1",
                title: "Analyser les objectifs et les modalités d’organisation d’un projet"
            },
            {
                id: "subskill2",
                title: "Planifier les activités"
            },
            {
                id: "subskill3",
                title: "Évaluer les indicateurs de suivi d’un projet et analyser les écarts"
            }
        ]
    },
    {
        id: "skill5",
        title: "Mettre à disposition des utilisateurs un service informatique",
        subSkills: [
            {
                id: "subskill1",
                title: "Réaliser les tests d’intégration et d’acceptation d’un service"
            },
            {
                id: "subskill2",
                title: "Déployer un service"
            },
            {
                id: "subskill3",
                title: "Accompagner les utilisateurs dans la mise en place d’un service"
            }
        ]
    },
    {
        id: "skill6",
        title: "Organiser son développement professionnel",
        subSkills: [
            {
                id: "subskill1",
                title: "Mettre en place son environnement d’apprentissage personne"
            },
            {
                id: "subskill2",
                title: "Mettre en œuvre des outils et stratégies de veille informationnelle"
            },
            {
                id: "subskill3",
                title: "Gérer son identité professionnelle"
            },
            {
                id: "subskill4",
                title: "Développer son projet professionnel"
            }
        ]
    }
];