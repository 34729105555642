import {FC, useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faMoon, faSun} from "@fortawesome/free-solid-svg-icons";
import {DarkModeContext} from "../Contexts/DarkModeContext";
import {Link} from "react-router-dom";

export const Header: FC = () => {

    const [navbarVisiblePhone, setNavBarVisiblePhone] = useState<boolean>(false);

    //@ts-ignore
    const [darkMode, setDarkMode] = useContext(DarkModeContext);

    return(
        <header className={"z-20 relative w-full h-14 bg-white flex justify-between border-b border-gray-200 dark:border-zinc-800 dark:bg-zinc-900"}>
            <Link to={"/"} className={"cursor-pointer font-semibold text-2xl w-fit flex flex-col justify-center px-5 bg-transparent"}>
                Portfolio
            </Link>
            <div className={(!navbarVisiblePhone&&"hidden") + " md:flex z-50 flex-1 md:justify-end md:top-0 md:relative md:w-fit md:flex-row md:bg-transparent absolute top-14 w-full flex-col bg-gray-100 dark:bg-zinc-800 dark:md:bg-transparent"}>
                <Link to={"/skills"} className={"navbar-item"}>
                    <div className={"mx-auto"}>
                        Compétences
                    </div>
                </Link>
                <Link to={"/works"} className={"navbar-item"}>
                    <div className={"mx-auto"}>
                        Travaux
                    </div>
                </Link>
                <Link to={"/technology"} className={"navbar-item"}>
                    <div className={"mx-auto"}>
                        VT
                    </div>
                </Link>
                <Link to={"/career"} className={"navbar-item"}>
                    <div className={"mx-auto"}>
                        Parcours
                    </div>
                </Link>
                <a href={"mailto:jeremydelmas@hotmail.com"} className={"navbar-item"}>
                    <div className={"mx-auto"}>
                        Contact
                    </div>
                </a>
            </div>
            <div className={"flex items-center"}>
                <div onClick={() => setDarkMode(!darkMode)} className={"select-none cursor-pointer w-fit flex flex-col justify-center mx-4"}>
                    {darkMode?
                        <FontAwesomeIcon className={"h-[20px]"} icon={faSun} />
                        :
                        <FontAwesomeIcon className={"h-[20px]"} icon={faMoon} />
                    }
                </div>
                <div onClick={() => setNavBarVisiblePhone(!navbarVisiblePhone)} className={"select-none cursor-pointer w-fit flex flex-col justify-center md:hidden mr-3"}>
                    <FontAwesomeIcon className={"h-[24px]"} icon={faBars} />
                </div>
            </div>
        </header>
    );
}