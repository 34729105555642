import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import {Technologies} from "./Properties/Technologies";
import React, {FC} from "react";
import {Link} from "react-router-dom";
import {Image} from "../Components/Image";
import {Collapsable} from "../Components/Collapsable";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {PDF} from "../Components/PDF";
import {Types} from "./Properties/Type";

const Content: FC = () => {
    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Pour ce projet, nous avions pour objectif de réaliser une application mobile.<br/>
                Celle-ci devait permettre de gérer la maintenance de stations, plots et vélos.<br/>
                <br/>
                Ma mission avait été de pouvoir <b>visualiser l'état de maintenance d'une station et de ses plots</b>.<br/>
                Je devais aussi rendre possible le <b>changement d'état de ceux-ci</b>.<br/>
                Il m'était aussi demandé de pouvoir <b>afficher un historique de changement d'état</b> des stations et des plots sur une période donnée.<br/>
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Résultat
            </h1>

            <p>L'utilisateur doit donc pouvoir se connecter à l'application:</p>
            <Image className={"w-1/5"} title={"Onglet de connexion"} src={"/images/works/ap33/onglet_connexion.webp"}/>

            <p className={"mt-4"}>Une fois connecté il a accès à la liste des stations et peut cliquer dessus pour afficher un menu:</p>
            <div className={"flex"}>
                <Image className={"w-1/5"} title={"Onglet listant les stations"} src={"/images/works/ap33/onglet_liste_stations.webp"}/>
                <Image className={"w-1/5 ml-4"} title={"Onglet listant les stations avec le menu affiché"} src={"/images/works/ap33/onglet_liste_stations_menu.webp"}/>
            </div>

            <p className={"mt-4"}>Voici les différents onglets liés à une station:</p>
            <div className={"flex"}>
                <Image className={"w-1/5"} title={"Historique des états d'une station"} src={"/images/works/ap33/onglet_historique_station.webp"}/>
                <Image className={"w-1/5 ml-4"} title={"Changer l'état d'une station"} src={"/images/works/ap33/onglet_changer_etat_station.webp"}/>
                <Image className={"w-1/5 ml-4"} title={"Liste des plots d'une station"} src={"/images/works/ap33/onglet_plots.webp"}/>
            </div>

            <p className={"mt-4"}>Si le technicien clique sur un plot il a comme pour les stations un menu qui s'affiche:</p>
            <Image className={"w-1/5"} title={"Onglet listant les plots avec le menu affiché"} src={"/images/works/ap33/onglet_plots_menu.webp"}/>

            <p className={"mt-4"}>Enfin, voici les deux onglets liés à un plot:</p>
            <div className={"flex"}>
                <Image className={"w-1/5"} title={"Historique des états d'un plot"} src={"/images/works/ap33/onglet_historique_plot.webp"}/>
                <Image className={"w-1/5 ml-4"} title={"Changer l'état d'un plot"} src={"/images/works/ap33/onglet_changer_etat_plot.webp"}/>
            </div>

            <h1 className={"underline font-bold mt-8"}>
                Compétences
            </h1>
            <p>
                <b>Gérer le patrimoine informatique</b><br/>
                → Gérer des sauvegardes: <i>Utilisation de Github</i>
                <br/>
                <b>Travailler en mode projet</b><br/>
                → Analyser les objectifs et les modalités d’organisation d’un projet: <i>Analyse de la demande et réalisation de celle-ci</i><br/>
                → Planifier les activités: <i>Utilisation de Jira pour tenir à jour notre progression</i>
                <br/>
            </p>
        </div>
    )
}

export const Ap33Work: Work = {
    id: "ap33",
    type: Types.BTS,
    title: "AP - VLib",
    description: "Réalisation d'une application mobile permettant la gestion et la maintenance de stations, plots et vélos.",
    date: "Avril 2022",
    languages: [Languages.JAVA],
    technologies: [Technologies.ANDROIDSTUDIO, Technologies.GIT],
    skills: {
        "skill1": ["subskill5"], //Git
        "skill4": ["subskill1", "subskill2"] //Jira
    },
    content: <Content/>
}