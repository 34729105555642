import {FC} from "react";
import {createPortal} from "react-dom";

export const useMeta: FC<{title: string}> = (props) => {

    const fTitle = props.title + " - Jérémy Delmas";
    const description = "Site portfolio de Jérémy Delmas";

    return createPortal(
        <>
            <title>{fTitle}</title>
            <meta
                name="description"
                content={description}
            />

            <meta
                name={"og:description"}
                content={description}
            />
            <meta
                name={"og:title"}
                content={fTitle}
            />
            <meta
                name={"og:site_name"}
                content={"Jérémy Delmas"}
            />

            <meta
                name={"twitter:card"}
                content={"summary_large_image"}
            />
            <meta
                name={"twitter:description"}
                content={description}
            />
            <meta
                name={"twitter:title"}
                content={fTitle}
            />
        </>,
        document.getElementsByTagName("head")[0]
    );
}