import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import {Technologies} from "./Properties/Technologies";
import {FC} from "react";
import {Image} from "../Components/Image";
import {Window} from "../Components/Window";
import {Types} from "./Properties/Type";

const Content: FC = () => {


    return(
        <div>
            <h1 className={"underline font-bold"}>
               Objectif
            </h1>
            <p>
                Durant ce projet, nous avions pour objectif de créer un <b>programme en C#</b> avec une <b>interface graphique</b>.<br/>
                Ce programme devait permettre de <b>générer des scripts SQL</b> à partir d'un ou de plusieurs fichiers texte.<br/>
                Voici une représentation du projet sous la forme d'un schéma:
            </p>
            <Image className={"w-2/3 mx-auto"} src={"/images/works/ap21/schema.webp"}/>
            <br/>
            <p>
                Un fichier .txt peut ressembler à ceci:
                <Window title={"joueur.txt"}>
                    N;A;A;A;A;A<br/>
                    NumJ;NomJ;PrenomJ;DateNaissJ;NumLicJ;CodeEquipe<br/>
                    1;ACHARD;Patrick;09/09/1978;8752504422;E11<br/>
                    2;ANDRE;Pascal;01/07/1970;5008121528;E11<br/>
                    3;ANDRI;Henri;08/02/1977;7629104910;E16<br/>
                    4;ARNAUD;Philippe;09/03/1978;2439258097;E5<br/>
                    5;AUDOUARD;Jean-Claude;02/08/1971;6281306978;E4<br/>
                    6;AUGAGNEUR;Jean-Paul;16/04/1970;1599486091;E21
                </Window>
                <br/>
                Le <b>nom du fichier</b>, ici <i>joueur.txt</i>, <b>représente le nom de la table ciblée</b> sans le .txt ce qui nous donne "joueur".<br/>
                La <b>première ligne</b> représente le <b>type de chaque colonne</b>, ici le 'N' veut dire <b>N</b>umérique et le 'A' veut dire <b>A</b>lphanumérique.<br/>
                La <b>seconde ligne</b> représente le <b>nom de chaque champs</b>.<br/>
                Enfin, <b>les autres lignes</b> sont les <b>occurences</b> à insérer.
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Résultat
            </h1>
            <p>
                Voici à quoi ressemble l'interface:
                <Image className={"w-2/3 mx-auto"} src={"/images/works/ap21/interface.webp"}/>
                Comme on peut le voir, l'utilisateur a juste à <b>sélectionner le dossier où se trouve les fichiers à convertir</b> en script SQL.<br/>
                Ensuite, il choisit dans la liste le fichier qu'il veut convertir, il peut aussi tous les convertir grâce au second bouton.<br/>
                <br/>
                <div className={"xl:flex"}>
                    <p className={"xl:flex-1 mr-3 my-3 xl:my-auto"}>
                        <b>Lors de la génération d'un script</b>, si un script avec le même nom existe déjà dans le dossier selectionné, le programme demande à l'utilisateur si il souhaite remplacer l'ancien par le nouveau.
                    </p>
                    <div className={"xl:flex-1 mx-auto xl:mx-0"}>
                        <Image className={"w-fit"} src={"/images/works/ap21/confirmer-remplacement.webp"}/>
                    </div>
                </div>
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Compétences
            </h1>
            <p>
                <b>Travailler en mode projet</b><br/>
                → Planifier les activités: <i>Pour ce projet, dans mon équipe nous utilisions Trello afin de planifier les tâches que chacun d'entre nous devrait effectuer.</i>
            </p>
        </div>
    );
}

export const Ap21Work: Work = {
    id: "ap21",
    type: Types.BTS,
    title: "AP - Générateur SQL",
    description: "Générer un script SQL à partir d'un document texte.",
    date: "Janvier 2021",
    languages: [Languages.CSHARP],
    skills: {
        "skill4": ["subskill2"] //Trello
    },
    content: <Content/>
}