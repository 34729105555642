import React from 'react';
import {Header} from "./app/Layout/Header";
import {Main} from "./app/Layout/Main";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HomePage} from "./app/Pages/HomePage";
import {DarkModeContextProvider} from "./app/Contexts/DarkModeContext";
import {NotFoundPage} from "./app/Pages/NotFoundPage";
import {WorksPage} from "./app/Pages/WorksPage";
import {WorkPage} from "./app/Pages/WorkPage";
import {SkillsPage} from "./app/Pages/SkillsPage";
import {CareerPage} from "./app/Pages/CareerPage";
import {TechnologyPage} from "./app/Pages/TechnologyPage";

function App() {
    return (
        <BrowserRouter>
            <DarkModeContextProvider>
                <div className={"flex flex-col h-full"}>
                    <Header/>
                    <Main>
                        <Routes>
                            <Route path={"/"} element={<HomePage/>}/>
                            <Route path={"/works"}>
                                <Route index element={<WorksPage/>}/>
                                <Route path={":id"} element={<WorkPage/>}/>
                            </Route>
                            <Route path={"/skills"} element={<SkillsPage/>}/>
                            <Route path={"/career"} element={<CareerPage/>}/>
                            <Route path={"/technology"} element={<TechnologyPage/>}/>
                            <Route path={"*"} element={<NotFoundPage/>}/>
                        </Routes>
                    </Main>
                </div>
            </DarkModeContextProvider>
        </BrowserRouter>

    );
}

export default App;
