import {BadgeColor, PBadge} from "../../Components/Badge";

export interface Type extends PBadge{
}

export const Types: {[name: string]: Type} = {
    PERSONAL: {
        color: BadgeColor.RED,
        text: "Personnel"
    },
    BTS: {
        color: BadgeColor.BLUE,
        text: "BTS SIO"
    },
    LP: {
        color: BadgeColor.DARK,
        text: "LP DAWIN"
    },
    INTERN: {
        color: BadgeColor.INDIGO,
        text: "Stage"
    }
}