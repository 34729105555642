import {FC} from "react";
import {useMeta} from "../uses/useMeta";

export const ErrorPage: FC<{title: string, subTitle: string}> = (props) => {
    return(
        <>
            {useMeta({title: props.title})}
            <div className={"absolute flex flex-col justify-center items-center w-full top-1/3 left-1/2 -translate-y-1/2 -translate-x-1/2"}>
                <div className={"font-bold flex flex-col items-center"}>
                    <div className={"text-blue-600 text-[50px] dark:text-purple-600"}>{props.title}</div>
                    <div className={"text-base text-[20px]"}>{props.subTitle}</div>
                </div>
            </div>
        </>

    );
}