import {FC, useContext} from "react";
import {Link} from "react-router-dom";
import {ParticlesLinesOverlay} from "../Components/Particles/ParticlesLinesOverlay";
import {DarkModeContext} from "../Contexts/DarkModeContext";
import {ParticlesCirclesOverlay} from "../Components/Particles/ParticlesCirclesOverlay";
import {useMeta} from "../uses/useMeta";

export const HomePage: FC = () => {

    //@ts-ignore
    const [darkMode, setDarkMode] = useContext(DarkModeContext);

    return(
        <>
            {useMeta({title: "Portfolio"})}
            <div className={"h-full absolute flex items-center justify-center w-full bottom-1"}>
                {darkMode?<ParticlesCirclesOverlay/>:<ParticlesLinesOverlay/>}
                <div className={"z-10 flex-col flex-col mx-auto w-fit"}>
                    <div className={"flex flex-col md:flex-row items-center justify-center"}>
                        <div className={"dark:bg-purple-600 bg-blue-600 rounded-full p-0.5 flex-none"}>
                            <video loop={true} autoPlay={true} muted={true} className={"rounded-full h-28 w-28"}>
                                <source type={"video/webm"} src={"/images/avatar.webm"}/>
                            </video>
                        </div>
                        <div className={"text-3xl font-bold flex flex-col items-start md:ml-16 mt-6 md:mt-0"}>
                            <div>Jérémy Delmas,</div>
                            <div>Apprenti <span className={"text-blue-600 dark:text-purple-600"}>LP DAWIN</span></div>
                            <div className={"mx-auto md:mx-0 text-base text-gray-600/90 dark:text-zinc-500"}>Développeur Web</div>
                        </div>
                    </div>
                    <div className={"mt-6 flex justify-center md:justify-end"}>
                        <Link to={"/works"} type="button" className="text-white bg-blue-700 dark:bg-purple-600 hover:text-white dark:hover:bg-purple-700 border-2 border-transparent hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-purple-700 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2">Mes travaux
                        </Link>
                        <a href={"mailto:jeremydelmas@hotmail.com"} type="button"
                           className="dark:bg-zinc-800 font-medium dark:hover:text-gray-300 hover:text-white text-gray-800 dark:text-gray-300 border-2 border-gray-800 dark:border-transparent dark:hover:bg-neutral-800 hover:bg-gray-800 bg-transparent focus:ring-4 focus:ring-gray-300 dark:focus:ring-zinc-700 rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2">Me contacter
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}