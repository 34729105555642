import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const Timeline: FC<{side: "RIGHT"|"LEFT", date: string, icon: any, title: any, subtitle: any}> = (props) => {

    return (
        <div className={(props.side === "RIGHT"&&"flex-row-reverse") + " flex"}>
            <div className={"py-6 mb-5 text-center mt-3 flex-1"}>
                <div className={(props.side==="LEFT"?"mr-3 ml-auto":"ml-3") + " w-fit"}>
                    <FontAwesomeIcon className={"text-center dark:text-gray-100 text-gray-700"} icon={props.icon}/>
                    <div className={"dark:text-gray-100 text-gray-700"}>{props.title}</div>
                    <div className={"text-gray-500 dark:text-zinc-500 text-sm"}>{props.subtitle}</div>
                </div>
            </div>

            <div className={(props.side==="LEFT"?"border-l":"border-r") + " border-gray-200 dark:border-zinc-600 relative flex-1"}>
                <div className={(props.side === "RIGHT" ? "-right-[9px]" : "-left-[8px]") + " bg-gray-200 dark:bg-zinc-600 bg- w-4 h-4 absolute top-0 rounded-md border-white dark:border-zinc-900 border-2"}/>
                <div className={(props.side === "LEFT"?"right-full mr-4 text-right":"left-full ml-4") + " -top-1 absolute text-gray-400 dark:text-zinc-700 w-full"}>{props.date}</div>
            </div>
        </div>
    )
}