import React, {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface PTabable{
    tabs: {
        name: string,
        icon?: any
    }[],
    style?: TabableStyle,
    mode?: TabableMode,
    classHeader?: string,
    classContent?: string,
    className?: string,
    classTabActive?: string,
    classTabInactive?: string,
}

export enum TabableMode {
    HIDE,
    REMOVE
}

export enum TabableStyle {
    //@ts-ignore
    WHITE_1 = {
        header: "bg-gray-50 dark:bg-zinc-800 dark:border-0 border-gray-200 border-2",
        content: "border-2 border-t-0 border-gray-200 dark:border-zinc-800 rounded-b-lg py-3 px-3 transition-opacity",
        tabInactive: "border-b-2 border-transparent transition-colors",
        tabActive: "border-b-2 border-blue-500 dark:border-purple-600 transition-colors"
    },
    //@ts-ignore
    WHITE_2 = {
        header: "bg-white dark:bg-zinc-800 dark:border-0 border-gray-200 border-2",
        content: "border-2 border-t-0 border-gray-200 dark:border-zinc-800 rounded-b-lg py-3 px-3",
        //@ts-ignore
        tabInactive: WHITE_1.tabInactive,
        //@ts-ignore
        tabActive: WHITE_1.tabActive
    }
}

export const Tabable: FC<PTabable> = (props) =>{

    const [tab, setTab] = useState(0);
    // @ts-ignore
    const [children, setChildren] = useState<any[]>(props.children===undefined?[]:(props.children.length === undefined?[props.children]:props.children));

    if(props.tabs.length !== children.length){
        console.error("[TABABLE] Size doesn't match ! Found " + children.length + " children but " + props.tabs.length + " tabs specified !")
        return null;
    }

    if(props.tabs.length === 0){
        console.warn("[TABABLE] Tabable component must have at least one tab specified !")
        return null;
    }

    return(
        <div className={props.className}>
            <div className={"w-full flex flex-col"}>
                {/*@ts-ignore*/}
                <div className={(props.classHeader?props.classHeader:(props.style?props.style.header:TabableStyle.WHITE_1.header))+" cursor-pointer box-content flex justify-center items-center rounded-t-lg"}>
                    {props.tabs.map((tabb, i) => (
                        //@ts-ignore
                        <div key={"tab-" + i} onClick={() => setTab(i)} className={(i===tab?(props.classTabActive?props.classTabActive:(props.style?props.style.tabActive:TabableStyle.WHITE_1.tabActive)):(props.classTabInactive?props.classTabInactive:(props.style?props.style.tabInactive:TabableStyle.WHITE_1.tabInactive))) + " select-none py-2.5 flex-1 text-base justify-center"}>
                            <div className={"flex mt-0.5 justify-center items-center text-center mr-2.5"}>
                                <div className={"hidden sm:block my-auto"}>
                                    {tabb.icon&&
                                        <FontAwesomeIcon className={"h-[20px]"} icon={tabb.icon} />}
                                </div>
                                <div className={"sm:ml-2 my-auto"}>
                                    {tabb.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*@ts-ignore*/}
                <div className={(props.classContent?props.classContent:(props.style?props.style.content:TabableStyle.WHITE_1.content))}>
                    {props.mode === TabableMode.REMOVE?
                        children[tab]
                        :
                        children.map((child, index) => <div key={"tab-"+index} className={index===tab?"":"hidden"}>{child}</div>)
                    }

                </div>
            </div>
        </div>
    )
}