import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faExpand, faWindowMinimize,} from "@fortawesome/free-solid-svg-icons";

export const Window: FC<{title: string}> = (props) => {

    return(
        <div className={"w-full flex flex-col"}>
            <div className={"flex text-[15px] justify-between bg-gray-100 dark:bg-zinc-800 px-4 py-2 rounded-t-lg"}>
                <div className={"font-medium"}>{props.title}</div>
                <div>
                    <FontAwesomeIcon className={"text-zinc-600 h-[15px] inline-block align-middle"} icon={faWindowMinimize} />
                    <FontAwesomeIcon className={"text-zinc-600 h-[20px] ml-3 inline-block align-middle"} icon={faClose} />
                </div>
            </div>
            <div className={"flex flex-wrap bg-transparent border border-gray-100 px-4 py-3 break-all dark:border-zinc-800 rounded-b-lg"}>
                {props.children}
            </div>
        </div>
    )
}