import {FC} from "react";
import {useParams} from "react-router-dom";
import {Work, Works} from "../Works/Works";
import {ErrorPage} from "./ErrorPage";
import {Breadcrumb} from "../Components/Breadcrumb";
import {Section} from "../Components/Section";
import {BadgeSection} from "../Components/BadgeSection";
import {Skill, Skills, SubSkill} from "../Works/Properties/Skills";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useMeta} from "../uses/useMeta";

export const WorkPage: FC = () => {

    const params = useParams();

    let work: Work|null = null;

    for(let fwork of Works){
        if(fwork.id === params.id){
            work = fwork;
        }
    }

    if(work === null){
        return (
            <ErrorPage title={"404"} subTitle={"Travail introuvable"}/>
        )
    }

    let skills: {skill: Skill, subSkills: SubSkill[]}[] = [];
    if(work.skills){
        for(let workSkill of Object.keys(work.skills)){
            let skillToAdd = null;
            let subSkillsToAdd = [];

            for(let skill of Skills){
                if(skill.id === workSkill){
                    skillToAdd = skill;
                    for(let subSkill of skill.subSkills){
                        if(work.skills[workSkill].includes(subSkill.id)){
                            subSkillsToAdd.push(subSkill);
                        }
                    }
                }
            }

            if(skillToAdd !== null && subSkillsToAdd.length > 0){
                skills.push({skill: skillToAdd, subSkills: subSkillsToAdd})
            }
        }
    }

    return(
        <>
            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
            {useMeta({title: work.title})}
            <Breadcrumb includeHome={true} items={[
                {
                    to: "/works",
                    name: "Travaux"
                },
                {
                    to: "/works/"+work.id,
                    name: work.title
                }
            ]}/>
            <div className={"mt-10"}>
                <div className={"flex flex-col-reverse justify-start lg:flex-row"}>
                    <div className={"flex-1 lg:mr-4 mt-4 lg:mt-0"}>
                        <Section title={"Description"}>
                            {work.description}
                        </Section>
                        {skills.length > 0&&
                            <div className={"mt-4"}>
                                <Section title={"Compétences"}>
                                    <div className={"mb-2 last:mb-0"}>
                                        {skills.map((skill, index) =>
                                            <div key={index} className={"mt-2 first:mt-0"}>
                                                <div className={""}>{index+1}. {skill.skill.title}</div>
                                                <div>
                                                    {skill.subSkills.map((subSkill, index) =>
                                                        <div key={index} className={"ml-4 flex justify-start"}>
                                                            <FontAwesomeIcon className={"h-[12px] top-1.5 relative"} icon={faArrowRight}/>
                                                            <span className={"ml-2"}>{subSkill.title}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Section>
                            </div>
                        }
                        <div className={"mt-4"}>
                            <Section title={"Contenu"}>
                                {work.content}
                            </Section>
                        </div>
                    </div>
                    <hr className={"border-gray-100 dark:border-zinc-800 lg:hidden"}/>
                    <div className={"flex-none lg:w-[300px] flex lg:flex-col flex-wrap lg:flex-nowrap lg:mb-0"}>
                        {work.languages&&
                            <div className={"mb-4 lg:mb-6 mr-4 lg:mr-0"}>
                                <BadgeSection title={"Langages utilisés"} badges={work.languages}/>
                            </div>
                        }

                        {work.technologies&&
                            <div className={"mb-4 lg:mb-6 mr-4 lg:mr-0"}>
                                <BadgeSection title={"Technologies utilisées"} badges={work.technologies}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}