import {FC, useEffect, useState} from "react";
import {Breadcrumb} from "../Components/Breadcrumb";
import {WorkCard} from "../Cards/WorkCard";
import {Search} from "../Components/Form/Inputs/Search";
import {Works} from "../Works/Works";
import {BadgeColor, PBadge} from "../Components/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGraduationCap, faSchool, faClock, faBuilding} from "@fortawesome/free-solid-svg-icons";
import {Timeline} from "../Components/Timeline";
import {useMeta} from "../uses/useMeta";

export const CareerPage: FC = (props) => {

    return (
        <div>
            {useMeta({title: "Parcours"})}
            <Breadcrumb includeHome={true} items={[
                {
                    to: "/career",
                    name: "Parcours"
                }
            ]}/>

            <div className={"mt-10"}>
                <div className={"flex flex-col"}>
                    <Timeline side={"RIGHT"}
                              date={"2017"}
                              icon={faSchool}
                              title={"Seconde Générale"}
                              subtitle={"Lycée Odilon Redon - Lesparre"}/>

                    <Timeline side={"LEFT"}
                              date={"2018"}
                              icon={faSchool}
                              title={"Première STI2D"}
                              subtitle={"Lycée Gustave Eiffel"}/>

                    <Timeline side={"RIGHT"}
                              date={"2019"}
                              icon={faGraduationCap}
                              title={"Terminale STI2D"}
                              subtitle={"Lycée Gustave Eiffel"}/>

                    <Timeline side={"LEFT"}
                              date={"2020"}
                              icon={faSchool}
                              title={<>BTS SIO 1<sup>ère</sup> année</>}
                              subtitle={"Lycée Gustave Eiffel"}/>

                    <Timeline side={"LEFT"}
                              date={"Septembre 2020"}
                              icon={faBuilding}
                              title={<>Stage de 1<sup>ère</sup> année</>}
                              subtitle={"Tikisoft"}/>

                    <Timeline side={"RIGHT"}
                              date={"2021"}
                              icon={faGraduationCap}
                              title={<>BTS SIO 2<sup>ème</sup> année</>}
                              subtitle={"Lycée Gustave Eiffel"}/>

                    <Timeline side={"RIGHT"}
                              date={"Janvier 2022"}
                              icon={faBuilding}
                              title={<>Stage de 2<sup>ème</sup> année</>}
                              subtitle={"Tikisoft - TikiLearning"}/>
                    <Timeline side={"LEFT"}
                              date={"2022"}
                              icon={faClock}
                              title={<>LP DAWIN</>}
                              subtitle={"IUT de Bordeaux"}/>
                </div>
            </div>
        </div>
    );
}