import {FC, useState} from "react";
import {Badge, PBadge} from "./Badge";
import {faSun} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum CollapsableColor{
    BLUE = "blue-500",
    GREEN = "green-400",
    RED = "red-500",
    YELLOW = "yellow-200",
    ORANGE = "orange-300",
    PURPLE = "purple-600"
}

const generate =<div className={
    "hover:border-blue-500 dark:hover:border-blue-500 " +
    "hover:border-green-400 dark:hover:border-green-400 " +
    "hover:border-red-500 dark:hover:border-red-500 " +
    "hover:border-yellow-200 dark:hover:border-yellow-200 " +
    "hover:border-orange-300 dark:hover:border-orange-300 " +
    "hover:border-purple-600 dark:hover:border-purple-600 "
}/>

export const Collapsable: FC<{title: string, color?: CollapsableColor, icon?: any, badge?:PBadge}> = (props) => {

    const [collapsed, setCollapsed] = useState<boolean>(true);

    return(
        <div className={(props.color&&("hover:border-"+props.color+ " dark:hover:border-"+props.color)) + " relative mt-3 w-fill bg-white dark:bg-zinc-900 dark:border-zinc-700 rounded-lg box-border border-gray-200 border-solid border-2"}>
            <div onClick={() => setCollapsed(!collapsed)} className={"select-none px-7 py-3 cursor-pointer flex justify-between items-center"}>
                <div className={"flex items-center"}>
                    {props.icon&&
                        //@ts-ignore
                        <FontAwesomeIcon className={"h-[20px] inline-block align-middle mr-4"} icon={props.icon} />}
                    <span className="inline-block align-middle">{props.title}</span>
                </div>

                    {props.badge&&
                        <div className={"break-normal ml-4 text-center"}>
                            <Badge {...props.badge}/>
                        </div>
                    }

            </div>

            {props.children&&
                <div className={(collapsed && "hidden") + " px-3 sm:px-7 py-3"}>
                    {props.children}
                </div>
            }
        </div>
    )
}