import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Work} from "../Works/Works";
import {Badge, BadgeColor} from "../Components/Badge";

export const WorkLineCard: FC<Work> = (props) => {

    return(
        <Link to={"/works/"+props.id} className="flex justify-between items-center flex-wrap px-6 py-3 w-full bg-white rounded-lg border border-gray-200 shadow-md dark:bg-zinc-800 dark:border-zinc-700">
            <h5 className="text-xl mr-2 font-bold tracking-tight text-gray-900 dark:text-white">
                {props.title}
            </h5>
            {props.languages&&
                <div className={"flex flex-wrap"}>
                    {props.languages.map((language, index) =>
                        <Badge key={index} text={language.text} color={language.color}/>
                    )}
                </div>
            }
        </Link>
    )
}