import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import React, {FC} from "react";
import {Technologies} from "./Properties/Technologies";
import {Tabable, TabableMode} from "../Components/Tabable";
import {PDF} from "../Components/PDF";
import {Types} from "./Properties/Type";

const Component: FC = () => {

    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Durant ce stage j'avais pour objectif de <b>réaliser un site dynamique proposant des formations en ligne</b>, pour cela j'ai du utiliser le framework <b>Symfony</b>, j'ai aussi pu faire du <b>TypeScript</b> et du <b>React</b>.<br/>
                Ce stage m'a apporté beaucoup de <b>connaissances techniques et professionnelles</b>.
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Compte rendu par semaine
            </h1>
            <Tabable className={"w-full mt-4"} mode={TabableMode.REMOVE} tabs={[
                {
                    name: "S1"
                },
                {
                    name: "S2"
                },
                {
                    name: "S3"
                },
                {
                    name: "S4"
                },
                {
                    name: "S5"
                },
                {
                    name: "S6"
                },
                {
                    name: "S7"
                },
                {
                    name: "S8"
                }
            ]}>
                <div>
                    <PDF path={"/files/works/tikilearning/r1.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r2.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r3.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r4.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r5.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r6.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r7.pdf"}/>
                </div>
                <div>
                    <PDF path={"/files/works/tikilearning/r8.pdf"}/>
                </div>
            </Tabable>
        </div>
    )
}

export const TikiLearningWork: Work = {
    id: "tikilearning",
    type: Types.INTERN,
    title: "TikiLearning",
    description: "Projet réalisé durant le stage de seconde année de BTS SIO au sein de l'entreprise Tikisoft avec pour objectif de produire un site internet.",
    date: "Janvier 2022",
    dateEnd: "Mars 2022",
    languages: [Languages.PHP, Languages.TYPESCRIPT],
    technologies: [Technologies.SYMFONY, Technologies.REACT, Technologies.GIT, Technologies.DOCKER],
    skills: {
        "skill1": ["subskill2", "subskill5"],
        "skill2": ["subskill1", "subskill3"],
        "skill3": ["subskill3"],
        "skill4": ["subskill2", "subskill3"],
        "skill5": ["subskill2"],
        "skill6": ["subskill1"]
    },

    content: <Component/>
}

// "skill1": [
//     "subskill2", //Adaptation
//     "subskill5" //Git
// ],
//     "skill2": ["subskill1", "subskill3"], //Jira + Réunions
//     "skill4": ["subskill1", "subskill2", "subskill3"], //Jira + Réunions
//     "skill5": ["subskill2"] //Le site en lui même