import {Collapsable, CollapsableColor} from "../Components/Collapsable";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {BadgeColor} from "../Components/Badge";
import {FC, useContext} from "react";
import {Skill} from "../Works/Properties/Skills";
import {Work, Works} from "../Works/Works";
import {WorkCard} from "./WorkCard";
import {DarkModeContext} from "../Contexts/DarkModeContext";
import {WorkLineCard} from "./WorkLineCard";


export const SkillCard: FC<Skill> = (props) => {

    //@ts-ignore
    const [darkMode, setDarkMode] = useContext(DarkModeContext);

    let worksBySubSkill: {[name: string]: Work[]} = {

    }

    for(let subSkill of props.subSkills){
        let worksForSubSkill = [];
        for(let work of Works){
            if(work.skills){
                for(let skill of Object.keys(work.skills)){
                    if(skill === props.id && work.skills[skill].includes(subSkill.id)){
                        worksForSubSkill.push(work)
                    }
                }
            }
        }
        worksBySubSkill[subSkill.id] = worksForSubSkill;
    }

    let totalUse = 0;
    for(let subSkill of Object.keys(worksBySubSkill)){
        totalUse += worksBySubSkill[subSkill].length;
    }

    return(
        <Collapsable title={props.title} color={darkMode?CollapsableColor.PURPLE:CollapsableColor.BLUE} icon={faChevronDown} badge={{color: totalUse > 0 ? BadgeColor.GREEN : BadgeColor.YELLOW, text: "Utilisée " + totalUse + " fois"}}>
            <span className={"text-zinc-400 text-sm"}>Sous compétences</span>
            {props.subSkills.map((subSkill, index) =>
                <Collapsable key={index}  title={subSkill.title} icon={faChevronDown} badge={{color: worksBySubSkill[subSkill.id].length > 0 ? BadgeColor.GREEN : BadgeColor.YELLOW, text: "Dans " + worksBySubSkill[subSkill.id].length + " trava" + (worksBySubSkill[subSkill.id].length > 1 ? "ux" : "il")}}>
                    {(worksBySubSkill[subSkill.id].length > 0)?
                        <>
                            <div className={"text-zinc-400 text-sm mb-3"}>Utilisée dans les travaux suivants</div>
                            {worksBySubSkill[subSkill.id].map((work, index) => {
                                return(
                                    <div key={index} className={"mt-2 first:mt-0"}><WorkLineCard {...work}/></div>
                                )
                            })}
                        </>
                        :
                        <span className={"text-zinc-400 text-sm"}>Utilisée dans aucun travail</span>
                    }
                </Collapsable>
            )}
        </Collapsable>
    )
}