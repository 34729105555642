import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import React, {FC} from "react";
import {Image} from "../Components/Image";
import {Collapsable, CollapsableColor} from "../Components/Collapsable";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {BadgeColor} from "../Components/Badge";
import {Types} from "./Properties/Type";

const Content: FC = () => {

    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Pour ce projet, nous avions pour objectif de créer un <b>programme en C#</b> avec une <b>interface graphique</b>.<br/>
                Celui-ci devait permettre la <b>gestion d'inscription de ligues et de membres</b> à des journées portes ouvertes.<br/>
                Pour la <b>base de données</b> nous devions utiliser <b>Access</b>.
            </p>
            <Collapsable title={"Modèle relationnel fournit"} color={CollapsableColor.BLUE} icon={faChevronDown}>
                <div>
                    LIGUE <i>(codeLigue, nom, adresse, ville, cp discipline)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> codeLigue<br/>
                    </div><br/>
                    MEMBRE <i>(codeMembre, nom, prénom, téléphone, mail, codeLigue)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> codeMembre<br/>
                        Clef étrangère : codeLigue en référence à codeLigue de LIGUE<br/>
                    </div><br/>
                    EQUIPEMENT <i>(codeEquipement, libellé)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> codeEquipement<br/>
                    </div><br/>
                    INSCRIPTION <i>(numInscription, longueur, largeur, codeLigue)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> numInscription<br/>
                        Clef étrangère : codeLigue en référence à codeLigue de LIGUE<br/>
                        Unicité INSCRIPTION.codeLigue<br/>
                    </div><br/>
                    DEMANDER <i>(numInscription, codeEquipement)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> numInscription, codeEquipement<br/>
                        Clef étrangère : numInscription en référence à numInscription de INSCRIPTION<br/>
                        Clef étrangère : codeEquipement en référence à codeEquipement de EQUIPEMENT<br/>
                    </div><br/>
                    CRENEAU <i>(codeCréneau, libelléCréneau)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> codeCréneau<br/>
                    </div><br/>
                    PARTICIPER <i>(codeMembre, codeCréneau)</i><br/>
                    <div className={"ml-5"}>
                        <b>Clef primaire :</b> codeMembre, codeCréneau<br/>
                        Clef étrangère : codeMembre en référence à codeMembre de MEMBRE<br/>
                        Clef étrangère : codeCréneau en référence à codeCréneau de CRENEAU
                    </div>
                </div>
            </Collapsable>

            <h1 className={"underline font-bold mt-8"}>
                Résultat
            </h1>

            <p>Page pour enregistrer une nouvelle ligue:</p>
            <div className={"flex"}>
                <Image className={"w-1/3"} title={"Page d'enregistrement d'une ligue"} src={"/images/works/ap23/enregistrer_ligue.webp"}/>
                <Image className={"w-1/3 ml-4"} title={"Editer une ligue"} src={"/images/works/ap23/editer_ligue.webp"}/>
            </div>
            <p>Comme on peut le voir, l'utilisateur a la possibilité de cliquer sur le bouton <b>"Nouveau"</b> afin d'ajouter une nouvelle ligue<br/>
                Il peut aussi cliquer sur une ligue existante pour la modifier.<br/></p>
            <br/>
            <p>Il y a la même chose pour l'<b>enregistrement de nouveaux membres</b></p>
            <div className={"flex"}>
                <Image className={"w-1/3"} title={"Page d'enregistrement d'un membre"} src={"/images/works/ap23/enregistrer_membre.webp"}/>
                <Image className={"w-1/3 ml-4"} title={"Editer un membre"} src={"/images/works/ap23/editer_membre.webp"}/>
            </div>
            <br/>
            <p>Il est aussi possible d'<b>enregistrer une ligue aux JPO</b>, il faut alors spécifier la taille de l'emplacement utilisé par la ligue et les équipements qu'elle va utiliser.</p>
            <Image className={"w-1/3"} title={"Page d'inscription d'une ligue aux JPO"} src={"/images/works/ap23/inscription_ligue.webp"}/>
            <br/>
            <p>J'ai aussi pu créer un <b>message d'alerte customisé</b> qui est utilisé dans les différents formulaires de l'application.<br/>
                Voici quelques exemples.</p>
            <div className={"flex"}>
                <Image className={"w-1/4"} title={"Alerte si les champs ne sont pas rempli"} src={"/images/works/ap23/alerte_erreur_inscription_membre.webp"}/>
                <Image className={"w-1/4 ml-4"} title={"Alerte lors de la création d'un membre"} src={"/images/works/ap23/alerte_inscription_membre.webp"}/>
                <Image className={"w-1/4 ml-4"} title={"Alerte lors de la suppression d'une inscription"} src={"/images/works/ap23/alerte_supprimer_inscription_ligue.webp"}/>
            </div>

            <h1 className={"underline font-bold mt-8"}>
                Compétences
            </h1>
            <p>
                <b>Travailler en mode projet</b><br/>
                → Analyser les objectifs et les modalités d’organisation d’un projet: <i>Analyse des besoins de la M2L et réalisation de ceux-ci</i><br/>
                → Planifier les activités: <i>Utilisation de Trello</i>
                <br/>
                <b>Mettre à disposition des utilisateurs un service informatique</b><br/>
                → Réaliser les tests d’intégration et d’acceptation d’un service: <i>Mise en place de tests afin d'assurer le bon fonctionnement de l'application</i>
                <br/>
            </p>
        </div>
    );
}

export const Ap23Work: Work = {
    id: "ap23",
    type: Types.BTS,
    title: "AP - M2L JPO",
    description: "Application permettant la gestion de ligues et de membres pour les JPO de la M2L.",
    date: "Mars 2021",
    languages: [Languages.CSHARP],
    skills: {
        "skill4": ["subskill1", "subskill2"],
        "skill5": ["subskill1"]
    },
    content: <Content/>
}