import {FC, useState} from "react";
import {Breadcrumb} from "../Components/Breadcrumb";
import {WorkCard} from "../Cards/WorkCard";
import {Search} from "../Components/Form/Inputs/Search";
import {Works} from "../Works/Works";
import {BadgeColor, PBadge} from "../Components/Badge";
import {useMeta} from "../uses/useMeta";

export const WorksPage: FC = () => {

    const [search, setSearch] = useState<string>("");

    return (
        <div>
            {useMeta({title: "Travaux"})}
            <Breadcrumb includeHome={true} items={[
                {
                    to: "/works",
                    name: "Travaux"
                }
            ]}/>

            <div className={"mt-10"}>
                <Search placeholder={"Chercher un travail"} value={search} onChange={e => setSearch(e.target.value)}/>

                <div className={"flex flex-wrap items-start justify-center"}>
                    {Works.map((work, index) => {

                        let emptyMatch = search.replaceAll(" ", "") === "";

                        let titleMatch = work.title.toLowerCase().includes(search.toLowerCase());

                        let descriptionMatch = work.description.toLowerCase().includes(search.toLowerCase());

                        let languageMatch = false;
                        if(work.languages){
                            for(let language of work.languages){
                                if(language.text.toLowerCase().includes(search.toLowerCase())){
                                    languageMatch = true;
                                }
                            }
                        }

                        if(!emptyMatch && !titleMatch && !descriptionMatch && !languageMatch){
                            return <></>;
                        }

                        let badge: PBadge|undefined = {text: "", color: BadgeColor.INDIGO}

                        if(emptyMatch){
                            badge = undefined;
                        }
                        else if(titleMatch){
                            badge.text = "Titre correspondant"
                        }
                        else if(descriptionMatch){
                            badge.text = "Description correspondante"
                            badge.color = BadgeColor.GREEN
                        }
                        else if(languageMatch){
                            badge.text = "Langage correspondant"
                            badge.color = BadgeColor.PINK
                        }

                        return(
                            <div key={work.id} className={"mr-5 mb-5 flex-grow min-w-[300px] max-w-[450px]"}><WorkCard badge={badge}  {...work}/></div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}