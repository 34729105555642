import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import {Technologies} from "./Properties/Technologies";
import React, {FC} from "react";
import {Collapsable, CollapsableColor} from "../Components/Collapsable";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {Image} from "../Components/Image";
import {Types} from "./Properties/Type";

const Content: FC = () => {
    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Pour ce projet, nous avions pour objectif de créer un <b>site statique</b>.<br/>
                Celui-ci devait permettre de <b>présenter les activités, ressources et ligues</b> de la M2L.<br/>
                Voici la maquette fournie:
                <Image className={"w-2/3"} title={"Maquette du design du site"} src={"/images/works/ap24/maquette.webp"}/>
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Résultat
            </h1>
            <p>Page d'accueil</p>
            <Image title={"Page d'accueil"} src={"/images/works/ap24/accueil.webp"}/>

            <p className={"mt-4"}>Page des services proposés</p>
            <Image title={"Page des services proposés"} src={"/images/works/ap24/accueil.webp"}/>

            <p className={"mt-4"}>Page des locaux de la M2L</p>
            <Image title={"Page des locaux de la M2L"} src={"/images/works/ap24/locaux.webp"}/>

            <h1 className={"underline font-bold mt-8"}>
                Compétences
            </h1>
            <p>
                <b>Développer la présence en ligne de l’organisation</b><br/>
                → Participer à l’évolution d’un site Web exploitant les données de l’organisation: <i>Création d'un site afin de présenter leur organisation</i>
                <br/>
                <b>Travailler en mode projet</b><br/>
                → Analyser les objectifs et les modalités d’organisation d’un projet: <i>Respect de la charte graphique fournie</i>
                <br/>
            </p>
        </div>
    )
}

export const Ap24Work: Work = {
    id: "ap24",
    type: Types.BTS,
    title: "AP - M2L Site Statique",
    description: "Réalisation d'un site statique pour présenter les activités d'une organisation.",
    date: "Mars 2021",
    languages: [Languages.HTML, Languages.CSS],
    skills: {
        "skill3": ["subskill3"],
        "skill4": ["subskill1"],
    },
    content: <Content/>
}