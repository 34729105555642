import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import {Technologies} from "./Properties/Technologies";
import React, {FC} from "react";
import {Image} from "../Components/Image";
import {Link} from "react-router-dom";
import {Collapsable} from "../Components/Collapsable";
import {PDF} from "../Components/PDF";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {Types} from "./Properties/Type";

const Content: FC = () => {
    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Pour ce projet, nous avions pour objectif d'améliorer le site statique créé lors de l'<Link className={"underline dark:text-purple-500 text-blue-500"} to={"/works/ap24"}>AP 2.4</Link>.<br/>
                Cette amélioration devait permettre de gérer de manière dynamique:
                <ul className={"list-disc ml-5"}>
                    <li>les ligues et clubs affiliés</li>
                    <li>les intervenants et les bénévoles</li>
                    <li>les bulletins et les contrats</li>
                </ul>
                <br/>
                Un MEA incomplet nous avait été fournit:
                <Image className={"w-2/3"} title={"MEA de l'application"} src={"/images/works/ap31/mea_fournit.webp"}/>
                <br/>
                Ma mission avait donc été de <b>gérer les intervenants</b> (salariés, bénévoles) <b>et les bulletins de salaire</b>.<br/>
                Les intervenants devaient aussi être <b>rattachés soit à une ligue, soit à un club, soit directement à la M2L</b>.<br/>
                Ceux-ci devaient aussi pouvoir <b>consulter leurs informations</b> (contrats, bulletins).
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Résultat
            </h1>
            <Collapsable title={"Compte rendu"} icon={faChevronDown}>
                <PDF path={"/files/works/ap31/compte_rendu.pdf"}/>
            </Collapsable>
            <br/>
            <p>L'utilisateur doit donc pouvoir se connecter:</p>
            <Image title={"Page de connexion"} src={"/images/works/ap31/page_connexion.webp"}/>

            <p className={"mt-4"}>Une fois connecté il peut accéder à son contrat, ses bulletins de salaire et ses divers informations personnelles:</p>
            <Image title={"Profil de l'utilisateur"} src={"/images/works/ap31/page_profil_salarie.webp"}/>

            <p className={"mt-4"}>Etant donné qu'il est RH il peut aussi accéder aux contrats et les gérer:</p>
            <div className={"flex"}>
                <Image className={"w-1/3 ml-4"} title={"Page listant les contrats"} src={"/images/works/ap31/page_liste_contrats.webp"}/>
                <Image className={"w-1/3 ml-4"} title={"Page pour créer un contrat"} src={"/images/works/ap31/page_nouveau_contrat.webp"}/>
                <Image className={"w-1/3 ml-4"} title={"Page pour modifier un contrat"} src={"/images/works/ap31/page_modifier_contrat.webp"}/>
                <Image className={"w-1/3 ml-4"} title={"Page pour supprimer un contrat"} src={"/images/works/ap31/page_supprimer_contrat.webp"}/>
            </div>
            <p><i>Il est également possible de faire la même chose avec les salariés.</i></p>

            <h1 className={"underline font-bold mt-8"}>
                Compétences
            </h1>
            <p>
                <b>Répondre aux incidents et aux demandes d’assistance et d’évolution</b><br/>
                → Traiter des demandes concernant les applications: <i>Amélioration du site statique afin de répondre aux demandes</i>
                <br/>
                <b>Développer la présence en ligne de l’organisation</b><br/>
                → Participer à l’évolution d’un site Web exploitant les données de l’organisation: <i>Création d'un site dynamique afin de gérer et présenter les informations de l'organisation</i>
                <br/>
                <b>Travailler en mode projet</b><br/>
                → Analyser les objectifs et les modalités d’organisation d’un projet: <i>Respect de la charte graphique fournie lors de la création du site statique</i><br/>
                → Planifier les activités: <i>Répartition des tâches avec mon équipier</i>
                <br/>
            </p>
        </div>
    );
}

export const Ap31Work: Work = {
    id: "ap31",
    type: Types.BTS,
    title: "AP - M2L Site Dynamique",
    description: "Réalisation d'un site dynamique afin de gérer les informations de la M2L.",
    date: "???",
    languages: [Languages.PHP],
    skills: {
        "skill2": ["subskill3"],
        "skill3": ["subskill3"],
        "skill4": ["subskill1", "subskill2"],
    },
    content: <Content/>
}