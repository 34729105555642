import {Work} from "./Works";
import {Languages} from "./Properties/Languages";
import {Technologies} from "./Properties/Technologies";
import React, {FC} from "react";
import {PDF} from "../Components/PDF";
import {Types} from "./Properties/Type";

const Content: FC = () => {

    return(
        <div>
            <h1 className={"underline font-bold"}>
                Objectif
            </h1>
            <p>
                Durant ce stage j'avais pour objectif de <b>réaliser un site vitrine proposant des formations</b>, pour cela j'utilisais <b>Bootstrap</b>.<br/>
                Ce stage m'a <b>apporté beaucoup de connaissances</b>: qu'est-ce qu'un Git, qu'est-ce que le DevOps, et d'autres choses.
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Comptes rendus
            </h1>
            <p>
                <PDF path={"/files/works/tikisoft/suivi.pdf"}/>
            </p>

            <h1 className={"underline font-bold mt-8"}>
                Compétences
            </h1>
            <p>
                <b>Gérer le patrimoine informatique</b><br/>
                → Gérer des sauvegardes: <i>Utilisation de Github</i>
                <br/>
                <b>Répondre aux incidents et aux demandes d’assistance et d’évolution</b><br/>
                → Traiter des demandes concernant les applications: <i>Utilisation de Trello</i>
                <br/>
                <b>Travailler en mode projet</b><br/>
                → Planifier les activités & Évaluer les indicateurs de suivi d’un projet et analyser les écarts: <i>Réunion quotidienne pour dire ce que j'ai fait le jour précédent et ce que je vais faire le jour actuel</i>
                <br/>
            </p>
        </div>
    );
}

export const Stage1Work: Work = {
    id: "stage1",
    type: Types.INTERN,
    title: "Tikisoft",
    description: "Stage de première année réalisé chez Tikisoft.",
    date: "Mai 2021",
    dateEnd: "Juin 2021",
    languages: [Languages.HTML, Languages.CSS],
    technologies: [Technologies.GIT],
    skills: {
        "skill1": ["subskill5"],
        "skill2": ["subskill3"],
        "skill4": ["subskill2", "subskill3"],
        "skill6": ["subskill2"]
    },
    content: <Content/>
}