import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faHouse} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export interface BreadCrumbItem{
    to: string,
    name: string
}

export const Breadcrumb: FC<{items: BreadCrumbItem[], includeHome: boolean}> = (props) => {

    return(
        <div>
            <div className={"flex items-center text-zinc-500 flex-wrap"}>
                {props.includeHome&&
                    <Link to={"/"} className={"flex items-center"}>
                        <FontAwesomeIcon icon={faHouse}/>
                        <span className={"ml-2 hover:underline"}>Accueil</span>
                    </Link>
                }

                {props.items.map((item, index) =>{
                    let inner = (<>
                        {(props.includeHome || index > 0) && <FontAwesomeIcon className={"mx-2 text-zinc-600"} icon={faChevronRight}/>}
                        <span className={index === props.items.length-1 ? "text-zinc-600" : "hover:underline"}>{item.name}</span>
                    </>);


                    if(index===props.items.length-1){
                        return(
                            <div key={index}>
                                {inner}
                            </div>
                        )
                    }

                    return(
                        <Link key={index} to={item.to}>
                            {inner}
                        </Link>
                    )
                })}
            </div>
            <div className={"text-xl mt-1 font-bold"}>
                {props.items[props.items.length-1].name}
            </div>
        </div>
    )
}